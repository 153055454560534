import React from 'react';
import {Helmet} from "react-helmet";
import Wallet from "../../components/User/Shared/Wallet";
import {useLocation, useSearchParams} from "react-router-dom";

const ConnectWallet = () => {
    const [searchParams] = useSearchParams();
    const prevRoute = searchParams.get('prevRoute');

    return (
        <React.Fragment>
            <Helmet>
                <title>
                    Connect wallet | NFT by Hand
                </title>
            </Helmet>
            {/* resources */}
            <section className="section" style={{paddingTop: '80px'}}>
                <div className="container">
                    <div className="row">
                        {/* section title */}
                        <div className="col-12 col-lg-8 offset-lg-2 col-xl-6 offset-xl-3">
                            <div className="section__title">
                                <h2>Connect Your Wallet</h2>
                                <p>
                                    Please connect your metamask wallet.
                                </p>
                            </div>
                        </div>
                        {/* end section title */}
                    </div>
                </div>

                <div className="container">
                    <div className="row row--grid">

                        <div className="col-12 col-lg-12">
                            <div className="resources resources--light">
						<span className="resources__icon">

                            <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 404 420.2" width="2404"
                                 height="2500">
                                <path
                                    className="st0" d="M382.9 290.9l-24.4 82.5-47.9-13.1z"/>
                                <path className="st0"
                                      d="M310.6 360.3l46.2-63.3 26.1-6.1z"/><path
                                className="st1" d="M347 228.7l35.9 62.2-26.1 6.1zM347 228.7l23.1-16 12.8 78.2z"/><path
                                className="st2" d="M317.6 181.7l66.4-27.2-3.3 14.9zM378.6 187.3l-61-5.6 63.1-12.3z"/><path
                                className="st2"
                                d="M378.6 187.3l-8.5 25.4-52.5-31zM391.9 160.8l-11.2 8.6 3.3-14.9zM378.6 187.3l2.1-17.9 8.9 7.4z"/><path
                                className="st3" d="M259.1 340.2l16.6 5.3 34.9 14.8z"/>
                                <path className="st2"
                                      d="M370.1 212.7l8.5-25.4 7.3 5.6zM370.1 212.7L301.2 190l16.4-8.3z"/><path
                                className="st2" d="M334 103.8l-16.4 77.9-16.4 8.3z"/>
                                <path className="st2"
                                      d="M384 154.5l-66.4 27.2 16.4-77.9z"/><path
                                className="st2" d="M334 103.8l69.3-8-19.3 58.7z"/>
                                <path className="st1"
                                      d="M370.1 212.7l-23.1 16-45.8-38.7z"/><path
                                className="st2" d="M400.3 39.7l3 56.1-69.3 8z"/>
                                <path className="st3"
                                      d="M400.3 39.7L261.8 140.4 260 72.1z"/><path
                                className="st1" d="M157 63.9l103 8.2 1.8 68.3z"/>
                                <path className="st2"
                                      d="M301.2 190l-39.4-49.6 72.2-36.6z"/><path
                                className="st0" d="M301.2 190l45.8 38.7-64.3 7.1z"/>
                                <path className="st0"
                                      d="M282.7 235.8l-20.9-95.4 39.4 49.6z"/><path
                                className="st2" d="M334 103.8l-72.2 36.6L400.3 39.7z"/>
                                <path className="st4"
                                      d="M157.9 358.7l32.7 34.1-44.5-40.5z"/><path
                                className="st5" d="M310.6 360.3l15.5-58.9 30.7-4.4z"/>
                                <path className="st3"
                                      d="M25.3 194.6l56.6-59.5-49.3 52.4z"/><path
                                className="st1" d="M356.8 297l-30.7 4.4 20.9-72.7zM261.8 140.4l-55.1-1.8L157 63.9z"/><path
                                className="st6" d="M347 228.7l-20.9 72.7-1.7-35.5z"/>
                                <path className="st5"
                                      d="M282.7 235.8l64.3-7.1-22.6 37.2z"/><path
                                className="st1" d="M206.7 138.6l55.1 1.8 20.9 95.4z"/>
                                <path className="st0"
                                      d="M206.7 138.6L58 1l99 62.9zM156.9 379.6l-129.2 40-25.8-98.5z"/><path
                                className="st2" d="M44.5 219.2l48.8-38.3 40.9 9.5z"/>
                                <path className="st2"
                                      d="M134.2 190.4l-40.9-9.5 21.9-90.4z"/><path
                                className="st2" d="M32.6 187.5l60.7-6.6-48.8 38.3z"/>
                                <path className="st5"
                                      d="M324.4 265.9l-25.2-12.7-16.5-17.4z"/><path
                                className="st2" d="M32.6 187.5l-4.3-22.7 65 16.1z"/>
                                <path className="st7"
                                      d="M294 281.8l5.2-28.6 25.2 12.7z"/><path
                                className="st1" d="M326.1 301.4L294 281.8l30.4-15.9z"/>
                                <path className="st2"
                                      d="M93.3 180.9l-65-16.1-5.3-19.1zM115.2 90.5l-21.9 90.4L23 145.7zM115.2 90.5l91.5 48.1-72.5 51.8z"/><path
                                className="st0" d="M134.2 190.4l72.5-51.8 32.3 98.7zM239 237.3l-100.2-2.1-4.6-44.8z"/><path
                                className="st1" d="M44.5 219.2l89.7-28.8 4.6 44.8zM282.7 235.8l-43.7 1.5-32.3-98.7z"/><path
                                className="st5" d="M299.2 253.2l-5.2 28.6-11.3-46z"/>
                                <path className="st2"
                                      d="M58 1l148.7 137.6-91.5-48.1z"/><path
                                className="st0" d="M1.9 321.1l125.4-5.2 29.6 63.7z"/>
                                <path className="st5"
                                      d="M156.9 379.6l-29.6-63.7 61.6-3z"/><path
                                className="st1"
                                d="M294 281.8l32.1 19.6 17.7 41.7zM138.8 235.2L1.9 321.1l42.6-101.9zM127.3 315.9L1.9 321.1l136.9-85.9zM282.7 235.8l8.2 29.8-39.6 2.3zM251.3 267.9L239 237.3l43.7-1.5z"/><path
                                className="st4" d="M190.6 392.8l-33.7-13.2 117.2 19.7z"/>
                                <path className="st2"
                                      d="M44.5 219.2l-19.2-24.6 7.3-7.1z"/><path
                                className="st8" d="M287.4 384.7l-13.3 14.6-117.2-19.7z"/>
                                <path className="st0"
                                      d="M293.7 348.9l-136.8 30.7 32-66.7z"/><path
                                className="st8" d="M156.9 379.6l136.8-30.7-6.3 35.8z"/>
                                <path className="st2"
                                      d="M23 145.7l-4.5-76.9 96.7 21.7zM32.6 187.5L19.3 174l9-9.2z"/><path
                                className="st5" d="M216.6 257.1l22.4-19.8-3.2 48.4z"/>
                                <path className="st5"
                                      d="M239 237.3l-22.4 19.8L184 274z"/><path
                                className="st1" d="M343.8 343.1l-6.1-4.5-43.7-56.8z"/>
                                <path className="st5"
                                      d="M184 274l-45.2-38.8 100.2 2.1z"/><path
                                className="st6" d="M235.8 285.7l3.2-48.4 12.3 30.6z"/>
                                <path className="st2"
                                      d="M14.9 153.3l8.1-7.6 5.3 19.1z"/><path
                                className="st7" d="M235.8 285.7L184 274l32.6-16.9z"/>
                                <path className="st2"
                                      d="M115.2 90.5L18.5 68.8 58 1z"/><path
                                className="st4" d="M274.1 399.3l5.5 15-89-21.5z"/>
                                <path className="st1"
                                      d="M188.9 312.9L184 274l51.8 11.7z"/><path
                                className="st6" d="M138.8 235.2L184 274l4.9 38.9z"/>
                                <path className="st1"
                                      d="M251.3 267.9l39.6-2.3 46.8 73zM138.8 235.2l50.1 77.7-61.6 3z"/><path
                                className="st6" d="M251.3 267.9l49.2 74.9-64.7-57.1z"/>
                                <path className="st1"
                                      d="M235.8 285.7l64.7 57.1-6.8 6.1z"/><path
                                className="st1" d="M293.7 348.9l-104.8-36 46.9-27.2zM337.7 338.6l-37.2 4.2-49.2-74.9z"/><path
                                className="st4" d="M347.5 373.8l-16 32.1-51.9 8.4zM279.6 414.3l-5.5-15 13.3-14.6z"/><path
                                className="st4" d="M287.4 384.7l9.8-3.9-17.6 33.5zM279.6 414.3l17.6-33.5 50.3-7z"/><path
                                className="st9" d="M337.7 338.6l12.3 7.5-37.4 4.5z"/>
                                <path className="st9"
                                      d="M312.6 350.6l-12.1-7.8 37.2-4.2zM308.3 356.5l45.5-5.5-6.3 22.8z"/><path
                                className="st9"
                                d="M347.5 373.8l-50.3 7 11.1-24.3zM297.2 380.8l-9.8 3.9 6.3-35.8zM293.7 348.9l6.8-6.1 12.1 7.8zM350 346.1l3.8 4.9-45.5 5.5z"/><path
                                className="st9" d="M308.3 356.5l4.3-5.9 37.4-4.5zM293.7 348.9l14.6 7.6-11.1 24.3z"/><path
                                className="st9" d="M312.6 350.6l-4.3 5.9-14.6-7.6z"/></svg>
							</span>
                                <h3 className="resources__title">Metamask Wallet</h3>
                                <p className="resources__text" style={{marginBottom: '30px'}}>
                                    MetaMask is a software cryptocurrency wallet used to interact with the EVM-based
                                    blockchains. It allows users to access their wallet through a browser
                                    extension or mobile app, which can then be used to interact with decentralized
                                    applications.
                                </p>

                                <Wallet prevRoute={prevRoute}/>

                            </div>
                        </div>

                    </div>
                </div>
            </section>
            {/* end resources */}
        </React.Fragment>
    )
}

export default ConnectWallet;