import React from 'react';
import CreateAsset from "../../components/User/Create/CreateAsset";

const Create = () => {
    return(
        <React.Fragment>
            <CreateAsset/>
        </React.Fragment>
    )
}

export default Create;